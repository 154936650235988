import jquery from 'jquery'
import $ from 'jquery'
// import 'jquery-ui-bundle'

if (typeof window !== 'undefined') {
  window.jQuery = jquery
  window.$ = $
}

globalThis.jQuery = globalThis.$ = $
